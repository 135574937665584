
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");


:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: rgb(226, 230, 44);
  --text-secondary: rgb(214, 127, 46);
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
  font-family: "Roboto", sans-serif;
}
h1,h2,h3,h4,h5,h6,p{
  font-family: "Anton", sans-serif,serif;
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

.navbar {
  position: fixed;
  z-index: 999;
  background-color: var(--bg-primary);
  transition: width 600ms ease;
  overflow: hidden;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
}

.nav-link-projects {
  filter: grayscale(0%) opacity(1);
  color: yellow;
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.nav-link:active {
  background-color: var(--text-secondary);
  transition: 100m;
}

.link-text {
  display: none;
  margin-left: 1rem;
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
}

.fa-primary {
  color: rgb(214, 127, 46);
}

.fa-secondary {
  color: rgb(214, 127, 46);
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo svg {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

.logo-text {
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}

.theme-icon {
  display: none;
}

.body {
  margin-left: 5rem;
  background-image: url("./images/body-background.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.main {
  margin-right: 0px;
}

.body-footer-spacer {
  min-height: 10vh;
}

.carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.footer {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  background-color: #23232e;
  margin-bottom: 0vw;
  left: 0;
  bottom: 0;
  right: 0;
}

a {
  text-decoration: none;
  border-radius: 10px;
}

.footer > button {
  background-color: #23232e;
  margin: 2px;
  border: 0px;
  border-radius: 10px;
  font-family: "Anton", sans-serif;
  color: var(--text-secondary);
}

.root {
  height: 100%;
}

.slide1 {
  background-image: url("./images/personal-portfolio.jpg");
  font-family: "Anton", sans-serif;
}

.slide2 {
  background-image: url("./images/webinfo-background.jpg");
  background-size: cover;
}

.slide3 {
  background-image: url("./images/react.jpg");
  text-align: center;
}
.slide3 .carousel-caption > div > h1
{
  color: #e2f10a;
}
.slide3 .carousel-caption > div > h5 {
  color: var(--text-secondary);
}
.slide3 > a > svg {
  margin-top: 15vh;
}
.slide4 {
  background-image: url("./images/second-slide-image.jpg");
}

.websiteinfo-div {
  margin-top: 2vh;
  border-radius: 10px;
}
.websiteinfo-div:hover {
  animation: logos-slowdown 250ms;

  background-color: rgba(204, 204, 204, 0.8);
}

@keyframes logos-slowdown {
  from {
    background-color: rgba(204, 204, 204, 0);
  }
  to {
    background-color: rgba(204, 204, 204, 0.6);
  }
}

.website-info {
  min-height: 80vh;
  min-width: 80vw;

  display: grid;
  gap: 0rem;

  grid-template-areas:
    "b b c c"
    "x x x x"
    "x x x x"
    "i i h h";

  grid-template-rows: repeat(4, 25%);
  grid-template-columns: repeat(4, 25%);

  --stagger-delay: 200ms;
}
/* entry animation */
@keyframes cardEntrance {
  from {
    opacity: 0;
    transform: scale(0.3);
    filter: hue-rotate(180deg);
  }
  to {
    opacity: 1;
    transform: scale(1);
    filter: hue-rotate(0deg);
  }
}
.website-info-cards {
  animation: cardEntrance 700ms ease-out;
  animation-fill-mode: backwards;
  opacity: 1;
  border-radius: 10px;
}

.info-card-1 {
  grid-area: b;
  background-image: url("./images/logos/html5-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  animation-delay: calc(2 * var(--stagger-delay));
}
.info-card-2 {
  grid-area: c;
  background-image: url("./images/logos/css-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  animation-delay: calc(3 * var(--stagger-delay));
}

.info-card-3 {
  grid-area: h;
  background-image: url("./images/logos/bootstrap-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  animation-delay: calc(4 * var(--stagger-delay));
}
.info-card-4 {
  grid-area: i;
  background-image: url("./images/logos/axios-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  animation-delay: calc(5 * var(--stagger-delay));
}

.info-card-5 {
  grid-area: x;
  animation-delay: calc(6 * var(--stagger-delay));
  background-image: url("./images/logos/react-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}






.links {
  padding: 9px 50px;
}
.cards-title {
  font-family: "Anton", sans-serif;
}

.main-row {
  display: flex;
  justify-content: space-evenly;
}

#\31 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/react-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\32 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/nodejs-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\33 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/javascript-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\34 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/c++-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\35 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/html5-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\36 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/css-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\37 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/Rest-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\38 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/bootstrap-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\39 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/azure-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\31 0 > .cards-skill-body {
  margin-top: -5px;
  background-image: url("./images/logos/mongodb-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\31 1 {
  margin-top: -5px;
  background-image: url("./images/logos/axios-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#\31 2 {
  margin-top: -5px;
  background-image: url("./images/logos/d3js-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cards-experience {
  background-color: rgba(204, 204, 204, 0.8);
  border: 2px;
  border-style: ridge;
  margin: 5px 5px;
  border-radius: 10px;
}
.cards-skill:hover {
  background-color: rgba(206, 206, 206, 0.9);
}
.cards-skill {
  background-color: rgba(251, 251, 251, 0);
  border: 2px;
  border-style: outset;
  margin-top: 2px;
  border-radius: 10px;
  height: 12vh;
  width: 12vw;
}
.skills-div {
  margin: 0px;
  padding: 0 1vw 0 2vw;
  border-radius: 10px;
  max-height: 30rem;
  width: 35vw;
  overflow-y: scroll;
  overflow-x: hidden;
}
.large-skill-summery-flex {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 5px;
  min-width: 45vw;
  max-height: 50vh;
  background-color: rgba(204, 204, 204, 0.8);
  border-radius: 10px;
}
.widget-large-skill > .each-Widget > .cards-skill {
  width: 30vw;
  height: 50vh;
  padding: 12px;
  margin-top: 6px;
  border: 0vw;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0);
}
.skillSummery-header {
  margin: 5px 0px;
  text-align: center;
  font-family: Anton;
}
.skillSummery-body {
  margin-top: 2vh;
}
.skill-summery {
  margin-top: 8px;
  margin-left: 4px;
  border-radius: 10px;
  width: 30vw;
  height: 60vh;
  max-height: 30rem;
  overflow-x: hidden;

  font-family: "Roboto", sans-serif;
}
.widget-large-skill {
  width: 30vw;
  height: 60vh;
}

.widget > div {
  background-color: rgba(229, 233, 233, 0);
}
.widget-half-div {
  background-color: rgba(229, 233, 233, 0);
  margin: -5px;
  min-height: 20vh;
}

.experiences-div {
  border-radius: 10px;
  padding-left: 2vw;
  max-height: 60vh;
  width: 90vw;

  overflow-y: scroll;
}
.experiences-body {
  font-family: "Roboto", sans-serif;
}
.cards-experience-header {
  text-align: center;
}

.cards-experience-title {
  text-align: center;
  font-family: "Anton", sans-serif;
}
.experiences-expand-button {
  size: inherit;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  transform: rotate(0deg);
  transition: 150ms;
}
.experiences-expand-button:active {
  transform: rotate(-180deg);
}

.h3 {
  margin: 2rem 0rem;
}
.h3-headers {
  margin: 2rem 0rem;
  border-radius: 5px;
  height: 3rem;
  font-family: "Anton", sans-serif;
  color: white;

  text-align: center;

  background-image: url("./images/main-grey-background.svg");
}

::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 1);
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(26, 23, 23);
  border-radius: 100vw;
}

.summery {
  border-radius: 10px;
  max-width: 90vw;
  margin: 0px 1vw 0vh 2vw;
  padding: 3px;
}
.summery-text > h3 {
  background-color: var(--bg-primary);
  color: var(--text-secondary);
  border-radius: 10px;
  text-align: center;
  font-family: "Anton", sans-serif;
}
.summery-text > p {
  padding: 4px;
  border-radius: 5px;
  background-color: rgba(204, 204, 204, 0.8);
  font-family: "Roboto" ,sans-serif;
}
.summery-text {
  size: inherit;
  font-size: 2vw;
  word-wrap: break-word;
  
}
.body-row {
  margin: 0;
  padding: 0px;
  padding-left: 0;
  margin-right: 1vw;
  /* this is needed for alignemnt */
}

/* Small screens */

@media only screen and (max-width: 700px) {
  .body {
    margin-left: 0px;
    padding-left: 0px;
  }
  .summery {
    margin-left: 4vw;
  }
  .summery-text {
    font-size: 4vw;
  }
  .large-skill-summery-flex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skill-summery {
    height: 25vh;
    width: 40vw;
    margin-right: 2vw;
  }
  .experiences-div {
    margin-left: 5px;
    width: 94vw;
  }
  .widget-half-div {
    margin: 0px 1px;
    padding-left: 0px;
  }
  .widget-large-skill {
    height: 25vh;
    width: 40vw;
  }
  .widget-large-skill > .each-Widget > .cards-skill {
    height: 25vh;
    width: 40vw;
  }

  .cards-skill {
    margin: 2px 2px;
    border-radius: 10px;
    height: 10vh;
    width: 15vw;
  }
  .skills-div {
    padding-right: 0px;
  }
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
  }
  .nav-item {
    margin: 2vw;
  }
  .nav-link {
    justify-content: space-around;
  }

  main {
    margin: 0;
  }
  .info-card-8 {
    visibility: hidden;
  }
  .info-card-9 {
    visibility: hidden;
  }
  .slide3 > a > svg{
    padding-right: 12vw;

  }

  
}

/* Large screens navbar*/
@media only screen and (min-width: 700px) {
  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;
  }

  .navbar:hover {
    width: 16rem;
  }

  .navbar:hover .link-text {
    display: inline;
  }

  .navbar:hover .logo svg {
    margin-left: 11rem;
  }

  .navbar:hover .logo-text {
    left: 0px;
  }
}

/* xl screen */
@media only screen and (min-width: 1500px) {
  .website-info {
    min-height: 90vh;
  }
  .websiteinfo-div {
    min-height: 90vh;
  }
  .summery-text {
    font-size: 1vw;
  }
  .skill-summery {
    margin-right: 4vw;
  }
}
